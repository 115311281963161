<template>
  <v-container class="max-width-1185px">
    <v-row class="text-center">
      <v-col class="d-none mb-4 mt-4" cols="12">
        <h1 class="display-1 font-weight-bold mb-3">
          Buchen Sie schnell und einfach Ihre Probefahrt
        </h1>
      </v-col>

      <v-col cols="8" class="mx-auto">
        <v-row>
          <v-col class="ma-auto">
            <v-card
              class="mx-auto pa-2 justify-center text-center car-card"
              elevation="0"
            >
              <div class="" align="center" justify="center">
                
                <p class="text-h5 ma-0 text-uppercase font-weight-bold">
                  {{ this.book_model_data.value_vehicle.brand + " " + this.book_model_data.value_vehicle.model }}
                </p>
                <label class="text-caption">{{
                  this.book_model_data.value_vehicle.model_and_description
                }}</label>
                <label class="text-caption">{{
                  this.book_model_data.value_vehicle.ps +
                  " | " +
                  this.book_model_data.value_vehicle.type_of_fuel +
                  " | " +
                  this.book_model_data.value_vehicle.transmission +
                  " | " +
                  this.book_model_data.value_vehicle.color
                }}</label>
                <br />
                <label :style="car_text_setting_data.location_css" for="">{{
                  this.book_model_data.value_vehicle.location_frontend_label
                }}</label>
              </div>
            </v-card>
          </v-col>
          <v-col>
              <v-img
              class="d-block car-card mx-auto"
              :src="base_url + this.book_model_data.value_vehicle.car_photo"
            />
          </v-col>

        </v-row>

      </v-col>

      <v-col class="mb-15" cols="12">
        <h2 class="headline font-weight-bold mb-10">
           {{ car_text_setting_data.appointment_select_text }}
        </h2>

        <v-row justify="center">
          <v-col class="custom-datepicker" cols="12" sm="6">
            <v-date-picker
              style="font-size: 16px"
              :min="current_date"
              v-model="value_datePicker"
              :first-day-of-week="1"
              locale="de-DE"
            ></v-date-picker>
          </v-col>
          <v-col cols="12" sm="6">
            <v-row justify="center" align="center">
              <v-chip-group
                active-class="primary--text"
                v-model="value_selected_slot"
                column
              >
                <v-chip
                  large
                  class="ma-2"
                  :value="n"
                  filter
                  v-for="n in items_available_slots"
                  :key="n.title"
                  :disabled="check_slot_booked(n)"
                >
                  {{ n.title }}
                </v-chip>
              </v-chip-group>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Step3",
  props: ['set_step2_data','unset_slots','car_text_setting_data'],
  methods: {
    ...mapActions([
      "get_available_slot",
    ]),
    
    check_slot_booked: function (slot) {
      if (slot.booked === 1) {
        return true;
      } else {
        return false;
      }
    },

    convertDate(str) {
      if (str === null || str === "") {
        return "";
      } else {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [day, mnth, date.getFullYear()].join(".");
      }
    },

  },
   async mounted() {
     
   },
  watch: {
    unset_slots(){
      this.value_datePicker= new Date().toISOString().substr(0, 10);
      this.value_selected_slot= "";
      this.items_available_slots= [];

      if(this.set_step2_data == null)
        return;

      if(this.set_step2_data.value_vehicle == null)
        return;

      this.book_model_data = this.set_step2_data;
      let car_details = {
          car: this.book_model_data.value_vehicle.name,
          date: this.book_model_data.value_datePicker,
        };

      this.get_available_slot(car_details).then((response) => {
        this.items_available_slots = response;
      });
    },
    set_step2_data(data){
      // this.value_type = null
      // this.value_model = null;
      // this.value_transmission = null;
      // this.value_type_of_fuel = null;
      // this.value_selected_slot = "";
      
      // this.value_brand = data

      // this.get_details({
      //     brand: this.value_brand.name,
      // }).then((response) => {
      //   this.items_model = response.model;
      //   this.items_transmission = response.transmission;
      //   this.items_type_of_fuel = response.type_of_fuel;
      // });
      // let car_type_details = {
      //     brand: this.value_brand.name,
      //   };
      //   this.get_car_details(car_type_details).then((response) => {
      //     this.vehicleResult = response;
          
      //   });
      this.book_model_data = data;
      let car_details = {
          car: this.book_model_data.value_vehicle.name,
          date: this.book_model_data.value_datePicker,
        };

        this.get_available_slot(car_details).then((response) => {
          this.items_available_slots = response;
        });
     
    },
    
    value_datePicker(newValue) {
      this.value_selected_slot = "";
      this.items_available_slots = [];
      if (
        typeof this.book_model_data.value_vehicle == "object" &&
        typeof this.value_datePicker == "string"
      ) {
        let car_details = {
          car: this.book_model_data.value_vehicle.name,
          date: this.value_datePicker,
        };

        this.get_available_slot(car_details).then((response) => {
          this.items_available_slots = response;
        });
        this.book_model_data.value_datePicker = newValue;
      } else {
        this.book_model_data.value_datePicker = null;
      }
    },
    value_selected_slot(newValue) {
      if (typeof this.value_selected_slot == "object") {
        this.book_model_data.value_selected_slot = newValue;
        var booking_data = {
          car: this.book_model_data.value_vehicle.name,
          booking_date: this.book_model_data.value_datePicker,
          from_time: newValue.from_time,
          to_time: newValue.to_time,
        };
        this.$emit("step3-data", booking_data);
        this.$emit("book_model_data", this.book_model_data);
        this.$emit("allow-booking-form", true); 
      } else {
        this.book_model_data.value_selected_slot = null;
      }
    },
    
    // book_model_data: {
    //   handler() {
    //     if (
    //       // typeof this.value_type == "object" &&
    //       // typeof this.value_brand == "object" &&
    //       typeof this.value_vehicle == "object" &&
    //       typeof this.value_datePicker == "string" &&
    //       // typeof this.value_model == "string" &&
    //       // typeof this.value_transmission == "string" &&
    //       // typeof this.value_type_of_fuel == "string" &&
    //       typeof this.value_selected_slot == "object"
    //     ) {
    //       this.$emit("allow-booking-form", false);
    //       var booking_data = {
    //         car: this.value_vehicle.name,
    //         booking_date: this.value_datePicker,
    //         from_time: this.value_selected_slot.from_time,
    //         to_time: this.value_selected_slot.to_time,
    //       };
    //       this.$emit("booking-data", booking_data);
    //     } else {
    //       this.$emit("allow-booking-form", true);
    //     }
    //   },
    //   deep: true,
    // },
  },
  data: () => ({
    base_url: "https://demo-backend.testdrive-heroes.com/",
    
    
    current_date: new Date().toISOString().substr(0, 10),
    value_datePicker: new Date().toISOString().substr(0, 10),
    value_selected_slot: "",
    items_available_slots: [],
    book_model_data: {
      value_type: "",
      value_brand: "",
      value_vehicle: "",
      value_datePicker: new Date().toISOString().substr(0, 10),
      value_model: "",
      value_transmission: "",
      value_type_of_fuel: "",
      value_selected_slot: "",
    },
    watcher_status: false,
  }),
};
</script>


<style>
.custom-datepicker .v-date-picker-table .v-btn {
  font-size: 16px;
}
.v-list-item__title {
  text-transform: uppercase;
}
.v-slide-group__content .v-card--link:before,
.v-card .v-card--link:before {
  background: white;
}
.v-slide-group__content .theme--light.v-sheet--outlined,
.v-card .theme--light.v-sheet--outlined {
  border-color: #1976d2;
  border-width: 2px;
}
.location-label {
  color: #1976d2;
}
.max-width-1185px{
  max-width: 1185px !important;
}
.car-card{
  width: 320px;
  margin: 0 20px;
}
@media only screen and (max-width: 600px) {
  .car-card{
    width: 200px;
    margin: 0;
  }
}
.v-application .text-caption{
  font-size: 14px !important;
}
</style>