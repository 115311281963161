import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Vuelidate from 'vuelidate'
import Axios from 'axios'
import VueCarousel from 'vue-carousel';

Axios.defaults.baseURL = 'https://demo-backend.testdrive-heroes.com/'
// Axios.defaults.baseURL = 'http://18.198.65.58/'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  Vuelidate,
  Axios,
  VueCarousel,
  render: h => h(App)
}).$mount('#app')
