<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4 mt-4" cols="12">
        <h1 class="display-1 font-weight-bold mb-3">
          {{ car_text_setting_data.title }}
        </h1>
      </v-col>

     
     
      <v-col class="mb-5 " cols="12">
        <h2 class="headline font-weight-bold mb-3">{{ car_text_setting_data.brand_select_text }}</h2>
        <v-row justify="center">
          <v-sheet class="mx-auto d-none d-md-block" max-width="1200" min-width="400">
            <v-slide-group v-model="value_brand" class="pa-4" show-arrows>
              <v-slide-item
                v-for="n in brand_details"
                :key="n.id"
                v-slot="{ active, toggle }"
                :value="n"
                :ref="n.name"
              >
                <!-- :disabled="check_brand_enabled(n)" -->
                <!-- :color="active ? 'primary' : ''" -->
                <v-card
                  :outlined="active ? true : false"
                  class="pa-2"
                  width="120"
                  :hidden="check_brand_enabled(n)"
                  elevation="0"
                  @click="toggle()"
                  
                >
                  <div class="" align="center" justify="center">
                    <v-img class="d-block" :src="base_url + n.logo" />
                    <label>{{ n.name }}</label>
                  </div>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>

          <v-container class="pa-1 d-block d-xs-block d-sm-block d-md-none">
            <v-item-group
              v-model="value_brand"
            >
              <v-row>
                <v-col
                  v-for="n in brand_details"
                   :key="n.id"
                  cols="6"
                  :ref="n.name"
                >
                  <v-item  :value="n" v-slot="{ active, toggle }">
                     <v-card
                        :outlined="active ? true : false"
                        class="mx-auto pa-1"
                        width="120"
                        :hidden="check_brand_enabled(n)"
                        elevation="0"
                        @click="toggle()"
                      >
                        <div class="" align="center" justify="center">
                          <v-img class="d-block" :src="base_url + n.logo" />
                          <label>{{ n.name }}</label>
                        </div>
                      </v-card>
                  </v-item>
                </v-col>
              </v-row>
            </v-item-group>
          </v-container>
        </v-row>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";


export default {
  name: "Step1",
  props: ["car_text_setting_data"],
  methods: {
    ...mapActions([
      "get_car_type_model_details",
    
    ]),
    prepare_car_type_model_details: function () {
      this.get_car_type_model_details().then((response) => {
        this.brand_details = response.brand_details; 
      });
    },
    check_brand_enabled: function (brand) {
      if (typeof brand.enable !== "undefined") {
        if (brand.enable === 1) {
          return false;
        } else {
          return true;
        }
      }
    },
  },
  async mounted() {
    await this.prepare_car_type_model_details();
  },
  watch: {
    
    value_brand(newValue) {
      this.$emit("step1-data", newValue); 
      this.$emit("allow-step-two", false);
    },
    
  },
  data: () => ({
    base_url: "https://demo-backend.testdrive-heroes.com/",
    value_brand: "",
    brand_details_filter: "",
    brand_details: null,
  }),
};
</script>


<style>
.custom-datepicker .v-date-picker-table .v-btn {
  font-size: 16px;
}
.v-list-item__title {
  text-transform: uppercase;
}
.v-slide-group__content .v-card--link:before,
.v-card .v-card--link:before {
  background: white;
}
.v-slide-group__content .theme--light.v-sheet--outlined,
.v-card .theme--light.v-sheet--outlined {
  border-color: #1976d2;
  border-width: 2px;
}
.location-label {
  color: #1976d2;
}
</style>