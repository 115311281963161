<template>
  <v-container class="max-width-1185px">
    <v-row>
      <v-col cols="8" class="mx-auto">

        <v-row v-if="this.book_model_data != null">
          <v-col cols="12" class="ma-auto text-center">
              <p class="text-h6 ma-0">{{car_text_setting_data.appointment_overview_text}}</p>
          </v-col>
          <v-col class="ma-auto">
            <v-card
              class=" mx-auto pa-2 justify-center text-center car-card"
              elevation="0"
            >
              <div class="" align="center" justify="center">
                
                <p class="text-h6 ma-0 text-uppercase">
                  {{ this.book_model_data.value_vehicle.brand + " " + this.book_model_data.value_vehicle.model }}
                </p>
                <label class="text-caption">{{
                  this.book_model_data.value_vehicle.model_and_description
                }}</label>
                <label class="text-caption">{{
                  this.book_model_data.value_vehicle.ps +
                  " | " +
                  this.book_model_data.value_vehicle.type_of_fuel +
                  " | " +
                  this.book_model_data.value_vehicle.transmission +
                  " | " +
                  this.book_model_data.value_vehicle.color
                }}</label>
                <br />
                <label class="location-label" for="">{{
                  this.book_model_data.value_vehicle.location_frontend_label
                }}</label>
                <br />
                <label class="text-caption"><b>Datum und Uhrzeit</b></label>
                <br />
                <label class="text-caption">{{convertDate(this.set_step3_data.booking_date)}}</label>
                <br />
                <label class="text-caption">{{this.set_step3_data.from_time.slice(0, -3) +" - "+ this.set_step3_data.to_time.slice(0, -3)}}</label>
              </div>
            </v-card>
          </v-col>
          <v-col>
              <v-img
              class="d-block car-card mx-auto"
              :src="base_url + this.book_model_data.value_vehicle.car_photo"
            />
          </v-col>
        </v-row>
        
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <form cols="12" sm="12">
          <v-text-field
            v-model="first_name"
            :error-messages="first_nameErrors"
            label="Vorname"
            class="required-field"
            required
            @input="$v.first_name.$touch()"
            @blur="$v.first_name.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="last_name"
            :error-messages="last_nameErrors"
            class="required-field"
            label="Nachname"
            required
            @input="$v.last_name.$touch()"
            @blur="$v.last_name.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="email"
            :error-messages="emailErrors"
            label="E-mail"
            class="required-field"
            required
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="street"
            :error-messages="streetErrors"
            class="required-field"
            label="Straße"
            required
            @input="$v.street.$touch()"
            @blur="$v.street.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="city"
            :error-messages="cityErrors"
            class="required-field"
            label="Ort"
            required
            @input="$v.city.$touch()"
            @blur="$v.city.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="postal_code"
            :error-messages="postal_codeErrors"
            class="required-field"
            label="PLZ"
            required
            @input="$v.postal_code.$touch()"
            @blur="$v.postal_code.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="phone_private"
            label="Telefon (privat)"
            required
            @blur="$v.phone_private.$touch()"
            @input="$v.phone_private.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="phone_mobile"
            :error-messages="phone_mobileErrors"
            class="required-field"
            label="Handy"
            required
            @input="$v.phone_mobile.$touch()"
            @blur="$v.phone_mobile.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="phone_home"
            label="Telefon (geschäftlich)"
            required
            @input="$v.phone_home.$touch()"
            @blur="$v.phone_home.$touch()"
          ></v-text-field>

           <!-- <v-select
              :items="this.items_locations"
              :error-messages="value_locationErrors"
              v-model="value_location"
              label="Standort"
              class="required-field"
              @input="$v.value_location.$touch()"
            >
              <template slot="no-data">
                <v-list-item>keine Einträge</v-list-item>  
              </template>
            </v-select> -->
            <v-label><strong>{{ car_text_setting_data.location_description }} </strong></v-label><br>
            <v-label>{{this.items_locations}}</v-label>

          <v-checkbox v-model="accepted" required
            @input="$v.accepted.$touch()"
            :value="true"
            @click="$v.accepted.$touch()"
            :error-messages="acceptedErrors" >
            <template v-slot:label>
              <div v-html="car_text_setting_data.privacy_checkbox_text_html">
                <!-- <div>
                Hiermit akzeptiere ich die Datenschutzerklärung unter 
                    <a
                      target="_blank"
                      href="http://testdrive-heroes.com/privacy"
                      id="privacy_link"
                    >
                      testdrive-heroes.com/privacy
                    </a> -->
                      
              </div>
            </template>


            
          </v-checkbox>
   

          
          <v-btn class="ma-2" @click="submit"> {{ car_text_setting_data.reservation_button_text}} </v-btn>
          <v-btn class="ma-2" @click="back"> {{ car_text_setting_data.back_button_step_4_text}} </v-btn>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  name: "BookingForm",
  mixins: [validationMixin],
  props:
    // ['set_booking_data']
    ['set_step3_data','book_model_data','car_text_setting_data']
  ,
  watch: { 
    set_step3_data: function(booking_data) { // watch it
      this.prepare_location_details(booking_data.car);
    }
  },
  validations: {
    first_name: { required  },
    last_name: { required},
    email: { required, email },
    street: { required },
    city: { required },
    postal_code: { required },
    // phone_private: {  },
    phone_mobile: { required },
    // phone_home: { },
    accepted: {required}
    // value_location: {required},
  },
  methods: {
    ...mapActions([
      "book_appointment","get_location"
    ]),
    prepare_location_details: function (car) {
      let car_details = {
          car: car
        };
      
      this.get_location(car_details).then((response) => {
        this.items_locations = response;
      });
    },
    convertDate(str) {
      if (str === null || str === "") {
        return "";
      } else {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [day, mnth, date.getFullYear()].join(".");
      }
    },
    submit(e) {
      e.preventDefault();
      this.$v.$touch();
      if (this.$v.$invalid) {
       
        return;
      } else {
        //create form data object
        const post_data = new FormData();
        var post_json = {
            car:this.set_step3_data.car,
            first_name:this.first_name,
            last_name:this.last_name,
            booking_date:this.set_step3_data.booking_date,
            from_time:this.set_step3_data.from_time,
            to_time:this.set_step3_data.to_time,
            email:this.email,
            street:this.street,
            city:this.city,
            postal_code:this.postal_code,
            phone_private:this.phone_private,
            phone_mobile:this.phone_mobile,
            phone_home:this.phone_home,
            is_accepted: this.accepted,
            // location: this.value_location
        };
        post_data.append("appointment_data", JSON.stringify(post_json));
        setTimeout(() => {
          this.book_appointment(post_data)
            .then((response) => {
              if(response){
                this.$emit('booking-submit', true);
              }else{
                this.$emit('booking-submit', false);
              }
            })
            .catch(() => {
              this.$emit('booking-submit', false);
            });
        });
      }
    },
    back(){
      this.$emit('booking-back', true);
    }
  },
  computed: {
    first_nameErrors() {
      const errors = [];
      if (!this.$v.first_name.$dirty) return errors;
      !this.$v.first_name.required && errors.push("Pflichtfeld.");
      return errors;
    },
    last_nameErrors() {
      const errors = [];
      if (!this.$v.last_name.$dirty) return errors;
      !this.$v.last_name.required && errors.push("Pflichtfeld.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Muss eine gültige E-Mail sein");
      !this.$v.email.required && errors.push("E-Mail ist ein Pflichtfeld");
      return errors;
    },
    streetErrors() {
      const errors = [];
      if (!this.$v.street.$dirty) return errors;
      !this.$v.street.required && errors.push("Pflichtfeld.");
      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.city.$dirty) return errors;
      !this.$v.city.required && errors.push("Pflichtfeld.");
      return errors;
    },
    postal_codeErrors() {
      const errors = [];
      if (!this.$v.postal_code.$dirty) return errors;
      !this.$v.postal_code.required && errors.push("Pflichtfeld.");
      return errors;
    },
    phone_mobileErrors() {
      const errors = [];
      if (!this.$v.phone_mobile.$dirty) return errors;
      !this.$v.phone_mobile.required && errors.push("Pflichtfeld.");
      return errors;
    },  
    // value_locationErrors() {
    //   const errors = [];
    //   if (!this.$v.value_location.$dirty) return errors;
    //   !this.$v.value_location.required && errors.push("Pflichtfeld.");
    //   return errors;
    // },  
    acceptedErrors() {
      const errors = [];
      if (!this.$v.accepted.$dirty) return errors;
      !this.$v.accepted.required && errors.push("Pflichtfeld.");
      return errors;
    },  
    
  },
  data: () => ({
      base_url: "https://demo-backend.testdrive-heroes.com/",
      first_name: null,
      last_name: null,
      email: null,
      street: null,
      city: null,
      postal_code: null,
      phone_private: null,
      phone_mobile: null,
      phone_home: null,
      accepted: null,
      // value_location: null,
      items_locations:null
  }),
};
</script>


<style>
.required-field.v-input .v-label::after {
  content: " *";
  color: red;
}
</style>