<template>
  <v-app>
    <!-- <v-app-bar app></v-app-bar> -->
    <v-main>
      <!-- <v-container >
        <v-row>
          <v-col> -->
            <Stepper/>
          <!-- </v-col>
        </v-row>
      </v-container> -->
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>
import Stepper from './components/Stepper';
import Footer from './components/Footer';

export default {
  name: 'App',

  components: {
    Stepper, Footer
  },
  created () {
      document.title = "Testdrive Heroes - Booking  Demo"
  }
};
</script>
